/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { Link, navigate } from 'gatsby'

import formatDate from '../../utils/formatDate'
import Text from '../common/Text'
import Space from '../common/Space'
import { LeftGradientBar } from '../common/GradientBars'

const Wrapper = styled.li`
  width: 100%;
  box-shadow: 0px 0px 14px 3px ${p => p.theme.color.dropShadow};
  background: ${p => p.theme.color.white};
  border: 0px solid transparent;
  border-left-width: 0px;
  border-radius: 4px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  transition: all 125ms ease-in-out;
  cursor: pointer;
  margin-bottom: 0px;

  &:hover {
    box-shadow: 0px 2px 20px 0px ${p => p.theme.color.darkShadow};
    transform: scale(1.01);
  }

  @media (max-width: 1050px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    &:hover {
      box-shadow: 0px 0px 14px 3px ${p => p.theme.color.dropShadow};
      transform: none;
    }
  }
`

const PostItem = ({ publishDate, title, description, uid, index }) => (
  <Wrapper
    className="link-list-item focus-outline"
    onClick={() => navigate(`/post/${uid}`)}
    onKeyDown={e => {
      if (e.key === 'Enter') navigate(`/post/${uid}`)
    }}
    tabIndex="0"
  >
    <LeftGradientBar index={index} />
    <Text smallBodyHeavy>{publishDate && formatDate(publishDate)}</Text>
    <Space height={8} />
    <Link to={`/post/${uid}`} onClick={e => e.stopPropagation()} className="no-focus" tabIndex="-1">
      <Text bodyHeavy numberOfLines={3} role="heading" aria-level="3">
        {title}
      </Text>
    </Link>
    <Space height={12} />
    <Text numberOfLines={2} style={{ minHeight: 60 }}>
      {description}
    </Text>
  </Wrapper>
)

export default PostItem
