/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Button from '../components/common/Button'
import Text from '../components/common/Text'
import Space from '../components/common/Space'

import PostItem from '../components/postSlices/PostItem'
import ExecutiveTeam from '../components/pageSlices/ExecutiveTeam'
import Chart from '../components/pageSlices/Chart'

export const query = graphql`
  query InvestorRelations {
    allPrismicPost(
      sort: { fields: data___publish_date, order: DESC }
      filter: { data: { post_type: { eq: "Press" }, publish_date: { ne: null } } }
      limit: 3
    ) {
      edges {
        node {
          uid
          data {
            description
            publish_date
            hide_from_feeds
            password
            title {
              richText
            }
          }
        }
      }
      totalCount
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: -49px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1050px) {
    margin-top: -29px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

const FeatureWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  justify-content: space-between;
  padding: 150px 0px 80px 0px;
  align-items: flex-start;

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 80px 10px;
  }
`

const Col = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const FeatureCol = styled(Col)`
  max-width: 500px;
`

const StockDataWrapper = styled.div`
  min-height: 177px;
  margin-top: 40px;
  width: 100%;
  h1.qmod-heading {
    font-family: ${p => p.theme.fonts.family.smallBody}!important;
  }
  .qmod-mkt-mid,
  span.qmod-datetime,
  span.qmod-volume {
    display: none;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -100%;
  height: 100%;
`

const ExecBG = styled(BG)`
  background-color: ${p => p.theme.color.offWhite};
`

const ExecutiveCol = styled(FeatureWrapper)`
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 0px;

  @media (max-width: 1050px) {
    padding: 40px 10px;
  }
`

const InvestorRelations = props => {
  const { allPrismicPost } = props.data

  const ExecutiveTeamContent = [
    {
      primary: {
        title: 'Analyst Coverage',
      },
      items: [
        {
          name: {
            text: 'Zach Cummins',
            richText: [
              {
                spans: [],
                text: 'Zach Cummins',
                type: 'heading3',
              },
            ],
          },
          function: {
            richText: [
              {
                spans: [],
                text: `B. Riley FBR
              zcummins@brileyfbr.com
              (310) 689-2227`,
                type: 'paragraph',
              },
            ],
          },
        },
        {
          name: {
            text: 'Allen Klee, CFA',
            richText: [
              {
                spans: [],
                text: 'Allen Klee, CFA',
                type: 'heading3',
              },
            ],
          },
          function: {
            richText: [
              {
                spans: [],
                text: `Maxim Group
              aklee@maximgrp.com
              (212) 895-3851`,
                type: 'paragraph',
              },
            ],
          },
        },
      ],
    },
    {
      primary: {
        title: 'Investor Relations',
      },
      items: [
        {
          name: {
            text: 'Matt Glover or Tom Colton',
            richText: [
              {
                spans: [],
                text: 'Matt Glover or Tom Colton',
                type: 'heading3',
              },
            ],
          },
          function: {
            richText: [
              {
                spans: [],
                text: `Gateway Investor Relations
              AEYE@gatewayir.com
              (949) 574-3860`,
                type: 'paragraph',
              },
            ],
          },
        },
      ],
    },
  ]

  return (
    <Layout customSEO showHeader showFooter hideHeaderFooterNav={false} showCTA>
      <SEO
        title="Investor Relations | AudioEye"
        pathname={typeof window !== 'undefined' ? window.location.pathname : null}
        desc="AudioEye's common stock trades on the Nasdaq under the symbol AEYE. AudioEye is an industry-leading
        software solution helping deliver website accessibility compliance at all price points to businesses of
        all sizes."
      />
      <Wrapper>
        <Row>
          <FeatureWrapper className="feature-wrapper">
            <FeatureCol>
              <Text heading1 style={{ marginBottom: '16px' }}>
                Investor Relations
              </Text>
              <Text bodyHeavy>Company Profile</Text>
            </FeatureCol>
            <FeatureCol>
              <Text>
                AudioEye's common stock trades on the Nasdaq under the symbol "AEYE." AudioEye is an industry-leading
                software solution helping deliver website accessibility compliance at all price points to businesses of
                all sizes. Our solutions advance accessibility with patented technology that reduces barriers, expands
                access for individuals with disabilities, and enhances the user experience for a broader audience.
              </Text>
              <StockDataWrapper className="stock-wrapper" />
            </FeatureCol>
            <BG className="bg" />
          </FeatureWrapper>
        </Row>
        <Row>
          <Col>
            <Text heading3 aria-level="2">
              Latest Press Releases
            </Text>
            <ul>
              {allPrismicPost &&
                allPrismicPost.edges.map((item, i) => {
                  if (!item.node.data.password && !item.node.data.hide_from_feeds) {
                    return (
                      <>
                        <Space height={24} />
                        <PostItem
                          uid={item.node.uid}
                          publishDate={item.node.data.publish_date}
                          title={item.node.data.title.richText[0].text}
                          description={item.node.data.description}
                          index={i}
                        />
                      </>
                    )
                  }
                  return null
                })}
            </ul>
            <Space height={16} />
            <Button
              text="View All"
              aria-label="View all press releases"
              size="small"
              to="/press"
              style={{ marginLeft: 'auto' }}
            />
          </Col>
        </Row>
        <Space height={80} />
        <Row>
          <Chart />
        </Row>
        <ExecutiveCol>
          {ExecutiveTeamContent.map(item => (
            <Row>
              <ExecutiveTeam {...item} />
            </Row>
          ))}
          <ExecBG className="bg" />
        </ExecutiveCol>
      </Wrapper>
    </Layout>
  )
}

export default InvestorRelations
