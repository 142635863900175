import React from 'react'
import styled from '@emotion/styled'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import Space from '../common/Space'
import Text from '../common/Text'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: ${p => p.theme.color[p.backgroundColor] || p.theme.color.offWhite};
  margin-top: -80px;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 40px 0px;
  width: 100%;
  flex: 1;
  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  flex-wrap: wrap;
`
const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
`

const Wrap = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px 4px;

  @media (max-width: 1050px) {
    margin: 0px 8px;
  }
`

const ExecutiveTeam = ({ items, primary }) => {
  const { title } = primary
  const backgroundColor = primary.background_color

  return (
    <Wrapper>
      <Space height={16} />
      <Col>
        <Text role="heading" aria-level="2" heading3>
          {title}
        </Text>
      </Col>
      <Space height={16} />
      <Col style={{ flex: 2 }}>
        <Row>
          {items.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Wrap key={`${item.name.text}-${i}`}>
              <Text richText font="bodyHeavy">
                <RichText render={item.name.richText} />
              </Text>
              <Space height={6} />

              <Text style={{ marginLeft: 3 }}>
                <RichText render={item.function.richText} />
              </Text>
              <Space height={20} />
            </Wrap>
          ))}
        </Row>
      </Col>
      <BG className="bg" backgroundColor={backgroundColor} />
    </Wrapper>
  )
}

export default ExecutiveTeam
